import { TranslateResult } from 'vue-i18n';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { capitalize } from 'lodash';
import { db } from '@/firebase';
import BigNumber from 'bignumber.js';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import ProgressBar from '@/components/common/progress-bar/ProgressBar.vue';
import StaticErrors from '@/components/common/StaticErrors/StaticErrors.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import { formatNumber } from '@/filters/number';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { Checkout, CheckoutStepNames } from '@/store/models/checkout';
import { PaymentInitData } from '@/store/modules/payment';
import { Investment, Referral } from '@/store/models/investment';
import { Coupon } from '@/store/models/user';
import { requireContractAgreement } from '../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    ProgressBar,
    StaticErrors,
    WithDownloads,
    [VueNumberInput.name]: VueNumberInput,
    ValidationProvider,
    ValidationObserver,
    FormInvalidMessage,
    CheckoutFooter,
  },
})

export default class CheckoutInvestment extends Vue {
  amountTrees: number = 1;
  selectedPaymentMode: string | null = null;
  selectedPaymentMethod: string | null = null;
  enteredCode: string = '';
  referrals: Referral[] = [];
  coupons: Coupon[] = [];
  correctCode = false;
  // Based on the whitelabel.config show/skip the contract agreement step
  requireContractAgreement: boolean = requireContractAgreement;
  paymentMethodsAllowed: { value: string, label: string }[] = [];
  formatNumber = formatNumber;

  @Action(addToastMessage) addToastMessage!: Function;
  @Action paymentInit!: (data: PaymentInitData) => void;
  @Action resetPayment!: Function;
  @Action updateCheckoutAction!: Function;
  @Action resetCheckoutAction!: (initValue?: Partial<Checkout>) => void;
  @Action callMetaAPI!: ({ eventName, extraInfo }: { eventName: string, extraInfo?: { [key: string]: unknown } }) => void;

  @ClassState payment!: State['payment'];
  @ClassState idin!: State['idin'];
  @ClassState user!: State['user'];
  @ClassState investments!: State['investments'];

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Getter getAssetById!: (string) => Asset;
  @Getter getInvestmentByAsset!: (string) => Investment;
  @Getter isEligibleToInvest!: boolean;
  @Getter getSharesTotalInvested!: number;
  @Getter getCheckout!: Checkout;
  @Getter('getAssetAddress') assetAddress!: string;

  async mounted(): Promise<void> {
    const resetStore = this.fundId !== this.getCheckout.assetId;
    if (resetStore) {
      this.resetCheckoutAction({
        eurAmount: this.inputMin,
        nextStep: this.getCheckout.nextStep,
      });
    }

    this.amountTrees = this.getCheckout!.sharesAmount;
    this.selectedPaymentMethod = this.paymentMethodOptions[0].value;

    if (!resetStore) {
      this.form.validate();
    }
  }

  created(): void {
    this.resetPayment();
  }

  @Watch('selectedPaymentMode')
  onSelectedPaymentModeChange(newMode): void {
    if (newMode !== 'one-off') {
      this.enteredCode = '';
      this.correctCode = false;
    }
  }

  @Watch('investments', { immediate: true })
  async onInvestmentsChange(): Promise<void> {
    if (!this.getSharesTotalInvested) {
      await db.collection('referral')
        .where('deleted', '==', false)
        .orderBy('createdDateTime', 'desc')
        .onSnapshot((snapshot): void => {
          this.referrals = snapshot.docs.map((doc): Referral => ({
            ...doc.data() as Referral,
            id: doc.id,
          }));
        });
    } else {
      await db.collection('investors').doc(this.user!.id).collection('coupon')
        .where('deleted', '==', false)
        .orderBy('createdDateTime', 'desc')
        .onSnapshot((snapshot): void => {
          this.coupons = snapshot.docs.map((doc): Coupon => ({
            ...doc.data() as Coupon,
            id: doc.id,
          }));
        });
    }
  }

  @Watch('asset', { immediate: true })
  onAssetChange(): void {
    this.paymentMethodsAllowed = [];
    this.paymentModeOptions.forEach((option): void => {
      if (this.paymentMethodAllowed(option.value)) {
        this.paymentMethodsAllowed.push(option);
      }
    });
    if (this.paymentMethodsAllowed.length > 0) {
      this.selectedPaymentMode = this.paymentMethodsAllowed[0].value;
    }
  }

  @Watch('isGTMDataAvailable', { immediate: true })
  changeToisGTMDataAvailable(val, oldVal): void {
    if (val && !oldVal && this.$gtm?.enabled()) {
      this.$gtm.trackEvent({
        event: 'invest_visit',
        email: this.user?.email,
        property: this.asset?.name.en,
      });
      this.callMetaAPI({ eventName: 'InitiateCheckout' });
    }
  }

  get isGTMDataAvailable(): boolean {
    return (!!this.user?.email && !!this.asset?.name);
  }

  get inputMin(): number {
    return 0;
  }

  get inputMax(): number {
    if (!this.asset) {
      return 0;
    }

    const investment = this.getInvestmentByAsset(this.asset.id);
    const maxAmount = (this.asset?.maxSharesPerInvestor && this.asset?.maxSharesPerInvestor - (investment?.boughtSharesTotal || 0)) || 0;
    const calculated = maxAmount || this.asset.sharesAvailable;

    const hardcoded = 1_000_000;

    return calculated > hardcoded ? hardcoded : calculated;
  }

  get paymentModeOptions(): { value: string, label: string }[] {
    return [
      { value: 'subscription', label: capitalize(this.$t('checkout.investment.recurringPayment').toString()) },
      { value: 'one-off', label: capitalize(this.$t('checkout.investment.singlePayment').toString()) },
      { value: 'gift-purchase', label: capitalize(this.$t('checkout.investment.giftPurchase').toString()) },
    ];
  }

  get paymentMethodOptions(): { value: string, label: string }[] {
    const options = [
      { value: 'ideal', label: this.$t('checkout.investment.ideal').toString() },
      { value: 'sofort', label: this.$t('checkout.investment.sofort').toString() },
      { value: 'sepa', label: this.$t('checkout.investment.sepa').toString() },
    ];

    if (this.selectedPaymentMode !== 'subscription') {
      options.push({ value: 'bancontact', label: this.$t('checkout.investment.bancontact').toString() });
    }

    return options;
  }

  // get dividendsReturnsOptions(): [string, number][] {
  //   return dividendsReturnsOptions(this.asset);
  // }

  /**
   * When to disable the payment button.
   */
  get disablePaymentButton(): boolean {
    return !!this.paymentLoading || !this.asset || !!this.paymentSuccess || this.asset.sharesAvailable <= 0;
  }

  get fundId(): string {
    return this.$route.params.id;
  }

  get asset(): Asset | undefined {
    return this.getAssetById(this.fundId);
  }

  get totalDiscount(): number {
    let discount = 0;

    if (this.selectedPaymentMode === 'subscription') {
      const discountValue = (this.asset!.subscriptionDiscountTable || [])
        .filter((discountGap): boolean => discountGap.sharesAmount <= this.amountTrees)
        .sort((a, b): number => b.sharesAmount - a.sharesAmount)[0];
      if (discountValue) {
        discount = new BigNumber(this.asset!.sharePrice).minus(discountValue.sharePrice).multipliedBy(this.amountTrees).toNumber();
      }
    }

    if (this.correctCode && !this.getSharesTotalInvested) {
      discount = new BigNumber(discount).plus(50).toNumber();
    }

    if (this.correctCode && this.getSharesTotalInvested) {
      discount = new BigNumber(discount).plus(50).toNumber();
    }

    return discount;
  }

  get investmentCosts(): number {
    return new BigNumber(this.amountTrees).multipliedBy(this.asset!.sharePrice).toNumber();
  }

  get totalCosts(): number {
    const costs = new BigNumber(this.investmentCosts).minus(this.totalDiscount || 0).toNumber();
    return costs <= 0 ? 0 : costs;
  }

  get placeHolderImage(): Object {
    return require('@/assets/images/property/properties-placeholder.jpg');
  }

  /**
   * Returns whether payment is loading/processing.
   *
   * @returns {boolean}
   */
  get paymentLoading(): boolean {
    return !!this.payment && this.payment.status === 'processing';
  }

  /**
   * Returns whether payment is a success.
   *
   * @returns {boolean}
   */
  get paymentSuccess(): boolean {
    return !!this.payment && this.payment.status === 'success';
  }

  get investorLoading(): boolean {
    return !this.user;
  }

  get selectedSubscription(): boolean {
    return this.selectedPaymentMode === 'subscription';
  }

  get codeTextReferral(): boolean {
    return !this.getSharesTotalInvested;
  }

  // eslint-disable-next-line consistent-return
  get nextStep(): { text: string | TranslateResult, note: string | TranslateResult | null, onClick: Function } | undefined {
    const updateCheck = (): void => {
      this.updateCheckoutAction({
        assetId: this.fundId,
        eurAmount: new BigNumber(this.amountTrees).multipliedBy(this.asset!.sharePrice).toNumber(),
        sharesAmount: this.amountTrees,
        investmentCosts: this.investmentCosts,
        totalCosts: this.totalCosts,
        discount: this.totalDiscount,
        type: this.selectedPaymentMode,
        method: this.selectedPaymentMethod,
        discountCode: this.enteredCode,
      });
    };
    switch (this.getCheckout.nextStep) {
      case CheckoutStepNames.Questionnaire:
        return {
          text: 'Submit',
          note: 'And go to next step',
          onClick: (): void => {
            updateCheck();
            this.$router.push({ path: `/${this.$route.params.lang}/checkout/questionnaire/${this.getCheckout.assetId}/` });
          },
        };
      case CheckoutStepNames.Terms:
        return {
          text: this.$t('checkout.investment.legalTerms'),
          note: this.$t('checkout.investment.finalize'),
          onClick: (): void => {
            updateCheck();
            this.$router.push({ path: `/${this.$route.params.lang}/checkout/legal/${this.getCheckout.assetId}/` });
          },
        };
      case CheckoutStepNames.Payment:
        return {
          text: 'Select',
          note: this.$t('checkout.legal.continue'),
          onClick: (): void => {
            updateCheck();
            this.paymentInit({
              redirectUrl: `${window.location.origin}/checkout/status/`,
              lang: localStorage.getItem('vue-i18n-language') || 'nl',
              brand: require('../../../../whitelabel.config').brand,
            });
          },
        };
      default:
        return {
          text: `Move to ${this.getCheckout.nextStep}`,
          note: null,
          onClick: (): void => {
            updateCheck();
          },
        };
    }
  }

  paymentMethodAllowed(type: string): boolean {
    if (this.asset?.checkoutPaymentOptions.subscription && type === 'subscription') {
      return true;
    }

    if (this.asset?.checkoutPaymentOptions.gift && type === 'gift-purchase') {
      return true;
    }

    if (this.asset?.checkoutPaymentOptions.oneOff && type === 'one-off') {
      return true;
    }

    return false;
  }

  get claimedCoupons(): string[] {
    return (this.user?.claimedCoupons as Coupon[])?.map((coupon): string => (coupon as Coupon).code) || [];
  }

  get allowedCoupons(): boolean {
    const existingCouponsArray = this.coupons.map((coupon): string => coupon.code);
    return existingCouponsArray.includes(this.enteredCode) && !this.claimedCoupons.includes(this.enteredCode);
  }

  get allowedReferralCodes(): string[] {
    return this.referrals.filter((referral): boolean => referral.investor.id !== this.user?.id).map((referral): string => referral.code);
  }

  validateCode(): void {
    const enteredCode = this.enteredCode.trim();

    if (enteredCode.length === 17) {
      if (!this.getSharesTotalInvested && this.allowedReferralCodes.includes(enteredCode)) {
        this.addToastMessage({
          text: this.$t('checkout.investment.referralCodeSuccess'),
          type: 'success',
        });
      this.correctCode = true;
      } else if (this.getSharesTotalInvested && this.allowedCoupons) {
        this.addToastMessage({
          text: this.$t('checkout.investment.couponCodeSuccess'),
          type: 'success',
        });
      this.correctCode = true;
      } else {
        this.addToastMessage({
          text: this.$t('checkout.investment.codeInvalid'),
          type: 'danger',
        });
      this.correctCode = false;
      }
    }
  }

  updateAsset(event): void {
    this.amountTrees = Number(event.target.value);
    if (this.$gtm?.enabled()) {
      this.$gtm.trackEvent({
        event: 'invest_select',
        email: this.user?.email,
        property: this.asset?.name.en,
        investment: this.totalCosts,
      });
    }
  }
}
